import { apiRes } from "@tential/ec-gql-schema/types/api"
import { CartItem } from "@tential/ec-gql-schema/models/order"
import { ProductAndSku, SkuWithEmbroidery, SkuWithGift } from "@tential/ec-gql-schema/types/cart"
import { UseManualCoupon, CartPriceState, ValidatedCoupon } from "~/types/cart"
// Path: apps/consumer/composables/useCart.ts
import { useStore } from "~/stores"
import { COOKIE_KEY } from "~/const/cookie"
import { AuthCookie } from "~/types/cookie"
import { karteCartEvent } from "~/utils/functions/karte"
import { useConfirmDialog } from "~/composables/useConfirmDialog"

interface CartInfo {
  isClass: boolean
  isShowCart: boolean
  scrollPosition: number
  paymentType: string
  useManualCoupon: UseManualCoupon
}

/*
 * useCart
 * reactiveな金額とカートリストの表示と表示の補助。（大元はstore.cartList, store.order.amount）
 * 及びカートアイテムの増減によるアクション（金額計算。外部に送信）
 */

export const provideUseCart = () => {
  const store = useStore()
  const { proceed } = useConfirmDialog()
  const { fetchGiftPattern } = useGift()

  const cartState: CartInfo = reactive({
    isClass: false,
    isShowCart: false,
    scrollPosition: 0,
    paymentType: "credit_card_batch",
    useManualCoupon: {
      type: "",
      id: "",
      code: "",
      userCouponId: "",
    },
  })

  const cartPriceState: Omit<CartPriceState, "cartList" | "outOfSkuIds" | "outOfSkuWithGifts"> = reactive({
    subTotal: 0,
    total: 0,
    postagePrice: 0,
    totalQuantity: 0,
    discountPrice: 0,
    commissionTotal: 0,
    giftPrice: 0,
    embroideryPrice: 0,
    usedCoupon: [],
    usedExchangeCoupon: false,
    totalPackageSize: 0,
    remainAmountItem: {
      remainAmount: 0,
      recommendDiscountMethod: "",
      recommendDiscountRate: 0,
      recommendDiscountFixedAmount: 0,
    },
  })

  /*
   * Ref
   */

  // 小計
  // 小計割引後
  // 税
  // 割引額合計
  // postage
  // items(明細)
  // - sku_id
  // - unit_price
  // - quantity
  // - unit_price_in_tax
  // - unit_price_in_tax_discount
  // const amountTotal = ref<Number>(0) // 税込合計
  // const amountTotalExcludeTax = ref<Number>(0) // 税抜合計
  // const amountTax = ref<Number>(0) // 税額
  const cartLine = ref<ProductAndSku[]>([])
  const calculateLoaded = ref<boolean>(false)
  // cartLine.value = store.cartList
  // アクションがあって、カートの中身が変わったら、合計金額などが変化する仕組み

  // 課題
  // useCartに責務をもたせすぎなのが問題
  // useCartのやることは
  // reactiveな金額とカートリストの表示と表示の補助。及びカートアイテムの増減によるアクション（金額計算。外部に投げるだけ）

  /*
   * Side Cart List Methods
   */
  // const disableBodyScroll = (showCart: boolean): void => {
  //   if (showCart) {
  //     document.addEventListener("touchmove", handleTouchMove, { passive: false })
  //     document.addEventListener("wheel", handleTouchMove, { passive: false })
  //   } else {
  //     document.removeEventListener("touchmove", handleTouchMove)
  //     document.removeEventListener("wheel", handleTouchMove)
  //   }
  // }

  /**
   * カートの金額計算
   * @param {CartItem[]} list - カートリスト
   * @param isAddedToCart - カートに商品を追加したか
   */
  const calcCart = async (list: CartItem[], isAddedToCart = true) => {
    if (!list || list.length <= 0) return
    calculateLoaded.value = false

    const cookie = useCookie<AuthCookie | null>(COOKIE_KEY.AUTH)

    const resValue = await $fetch<apiRes<CartPriceState>>("/api/payment/cart/price_calc", {
      method: "POST",
      headers: {
        custom_authorization: cookie.value?.accessToken ? `Bearer ${cookie.value.accessToken}` : "",
      },
      body: {
        cartList: list,
        payment_method: cartState.paymentType,
        use_manual_coupon: {
          type: cartState.useManualCoupon.type,
          id: cartState.useManualCoupon.id,
          code: cartState.useManualCoupon.code,
          userCouponId: cartState.useManualCoupon.userCouponId,
        },
        isAddedToCart,
      },
    })

    if (!resValue || resValue.status !== 200) return

    const resBody = resValue.data

    cartLine.value = resBody.cartList
    cartPriceState.subTotal = resBody.subTotal
    cartPriceState.total = resBody.total
    cartPriceState.postagePrice = resBody.postagePrice
    cartPriceState.totalQuantity = resBody.totalQuantity
    cartPriceState.discountPrice = resBody.discountPrice
    cartPriceState.commissionTotal = resBody.commissionTotal
    cartPriceState.giftPrice = resBody.giftPrice
    cartPriceState.embroideryPrice = resBody.embroideryPrice
    cartPriceState.usedCoupon = resBody.usedCoupon
    cartPriceState.usedExchangeCoupon = resBody.usedExchangeCoupon
    cartPriceState.totalPackageSize = resBody.totalPackageSize
    cartPriceState.remainAmountItem = resBody.remainAmountItem

    if (resBody.outOfSkuIds.length > 0) {
      store.cartList = store.cartList.filter((cartItem) => !resBody.outOfSkuIds.includes(cartItem.sku_id))
      // TODO: toastで売り切れた商品を表示 toastもcartもinjectしているためtoastがundefinedになるのでstateをpiniaに移行するなどの対応が必要
      alert("売り切れた商品はカートから削除されました")
    }

    // ギフトの在庫が切れた商品をカートから削除
    if (resBody.outOfSkuWithGifts.length > 0) {
      store.cartList = store.cartList.filter((cartItem) => {
        if (!cartItem.gift) return true
        const outOfCartItem = resBody.outOfSkuWithGifts.find((outOfSkuWithGift) => {
          return !!(
            outOfSkuWithGift.sku_id === cartItem.gift?.sku_id &&
            outOfSkuWithGift.message_sku_id === cartItem.gift?.message_sku_id &&
            outOfSkuWithGift.card_sku_id === cartItem.gift?.card_sku_id
          )
        })
        return !outOfCartItem
      })
      fetchGiftPattern()
      // TODO: toastで表示
      alert("ギフトの在庫が切れた商品はカートから削除されました")
    }

    // 手動クーポンで適応バリデーションに引っかかっていた場合
    if (resBody.manualCouponError) {
      alert("クーポンの利用条件を満たしていないためクーポンの適応が解除されました")
      resetManualCoupon()
    }

    // 交換クーポンだった場合
    if (resBody.usedExchangeCoupon) {
      cartState.paymentType = "cod"
    }

    calculateLoaded.value = true
    return resBody
  }

  const openCart = async (): Promise<void> => {
    await calcCart(store.cartList)
    cartState.isShowCart = true
  }

  const closeCart = (): void => {
    if (cartState.isShowCart) {
      setTimeout(() => {
        cartState.isClass = false
        cartState.isShowCart = false
        // disableBodyScroll(cartState.showCart)
      }, 250)
    }
  }

  // size, color, priceなどは入れない
  const addCartItem = async (cartItem: CartItem) => {
    store.addCartList(cartItem)
    const isAddedToCart = true
    await calcCart(store.cartList, isAddedToCart)
    const params = {
      addCart: true,
      productId: cartItem.product_id,
      cartLine: cartLine.value,
      cartLength: store.cartList.length,
      totalPrice: cartPriceState.total,
    }
    karteCartEvent(params)
  }

  // カート一括追加
  const addCartMultiItem = async (cartItems: CartItem[], productId: string) => {
    const isAddedToCart = true
    for (const item of cartItems) {
      store.addCartList(item)
    }
    await calcCart(store.cartList, isAddedToCart)
    const params = {
      addCart: true,
      productId,
      cartLine: cartLine.value,
      cartLength: store.cartList.length,
      totalPrice: cartPriceState.total,
    }
    karteCartEvent(params)
  }

  const removeCartItem = async (skuId: string, gift?: SkuWithGift, embroidery?: SkuWithEmbroidery) => {
    const cloneCartList = Array.from(store.cartList)
    const index = cloneCartList.findIndex((cartItem) => {
      // SKU＋ラッピングなし＋刺繍が一致
      if (!gift)
        return (
          cartItem.sku_id === skuId &&
          !cartItem.gift &&
          cartItem.embroidery?.text === embroidery?.text &&
          cartItem.embroidery?.color === embroidery?.color &&
          cartItem.embroidery?.font === embroidery?.font &&
          cartItem.embroidery?.position === embroidery?.position
        )
      // SKU＋カード＋刺繍が一致
      else if (!gift.sku_id && !gift?.message && gift?.card)
        return (
          cartItem.sku_id === skuId &&
          cartItem.gift &&
          !cartItem.gift.sku_id &&
          !cartItem.gift.message &&
          cartItem.gift.card === gift.card &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
      // SKU＋ラッピング＋メッセージ＋カード＋刺繍が一致
      else if (gift?.message && gift?.card)
        return (
          cartItem.sku_id === skuId &&
          cartItem.gift &&
          cartItem.gift.sku_id === gift.sku_id &&
          cartItem.gift.message === gift.message &&
          cartItem.gift.card === gift.card &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
      // SKU＋ラッピング＋メッセージ＋刺繍が一致
      else if (gift?.message && !gift?.card)
        return (
          cartItem.sku_id === skuId &&
          cartItem.gift &&
          cartItem.gift.sku_id === gift.sku_id &&
          cartItem.gift.message === gift.message &&
          !cartItem.gift.card &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
      // SKU＋ラッピング＋カード＋刺繍が一致
      else if (!gift?.message && gift?.card)
        return (
          cartItem.sku_id === skuId &&
          cartItem.gift &&
          cartItem.gift.sku_id === gift.sku_id &&
          !cartItem.gift.message &&
          cartItem.gift.card === gift.card &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
      // SKU＋ラッピング＋刺繍が一致
      else if (!gift?.message && !gift?.card)
        return (
          cartItem.sku_id === skuId &&
          cartItem.gift &&
          cartItem.gift.sku_id === gift.sku_id &&
          !cartItem.gift.message &&
          !cartItem.gift.card &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
      // その他
      else
        return (
          cartItem.sku_id === skuId &&
          cartItem.embroidery?.text === gift.embroidery?.text &&
          cartItem.embroidery?.color === gift.embroidery?.color &&
          cartItem.embroidery?.font === gift.embroidery?.font &&
          cartItem.embroidery?.position === gift.embroidery?.position
        )
    })
    const reduceItem = store.cartList[index]
    cloneCartList.splice(index, 1)
    store.cartList = cloneCartList

    if (store.cartList.length >= 1) {
      await calcCart(store.cartList)
    } else {
      cartLine.value = []
    }
    const params = {
      addCart: false,
      productId: reduceItem.product_id,
      cartLine: cartLine.value,
      cartLength: store.cartList.length,
      totalPrice: cartPriceState.total,
    }
    karteCartEvent(params)
  }

  const getCartCalcByPaymentType = (paymentType: string) => {
    cartState.paymentType = paymentType
    calcCart(store.cartList)
  }

  /** 手動クーポンが適応できるか、できる場合は適応する関数 */
  const checkAndUseManualCoupon = async (manualCoupon: UseManualCoupon): Promise<string | undefined> => {
    const cookie = useCookie<AuthCookie | null>(COOKIE_KEY.AUTH)
    const res = await $fetch<ValidatedCoupon>("/api/payment/cart/fetch_coupon", {
      method: "POST",
      headers: {
        custom_authorization: cookie.value?.accessToken ? `Bearer ${cookie.value.accessToken}` : "",
      },
      body: {
        useManualCoupon: manualCoupon,
        cartList: store.cartList,
        subTotal: cartPriceState.subTotal,
      },
    })
    if (res.status !== 200 || !res.body) {
      return res?.body.message || "クーポンの取得に失敗しました"
    }

    cartState.useManualCoupon = manualCoupon
    if (
      !res.body.canCombinedCoupon &&
      cartPriceState.usedCoupon.length > 0 &&
      !(await proceed(`他のクーポンとの併用はできません。\n現在適用中のクーポンを上書きしますか？`))
    )
      return ""

    await calcCart(store.cartList)
  }

  const resetManualCoupon = () => {
    cartState.useManualCoupon = {
      type: "",
      id: "",
      code: "",
      userCouponId: "",
    }
  }

  /** 決済手段と利用クーポン情報はorder/newから離れたらリセットする(今後は維持する形をとっても良いが自動クーポンが適応されなくなってしまうので考える必要あり) */
  const resetPaymentTypeAndCoupon = () => {
    cartState.paymentType = "credit_card_batch"
    cartState.useManualCoupon = {
      type: "",
      id: "",
      code: "",
      userCouponId: "",
    }
    calcCart(store.cartList)
  }

  const resetCart = () => {
    cartLine.value = []
    cartPriceState.subTotal = 0
    cartPriceState.total = 0
    cartPriceState.postagePrice = 0
    cartPriceState.totalQuantity = 0
    cartPriceState.discountPrice = 0
    cartPriceState.commissionTotal = 0
    cartPriceState.giftPrice = 0
    cartPriceState.usedCoupon = []
    cartPriceState.usedExchangeCoupon = false
    cartPriceState.totalPackageSize = 0
    cartState.paymentType = ""
    resetManualCoupon()
    store.cartList = []
    store.usedCoupons = []
  }

  /*
   * Calculator Logic
   */

  return {
    cartState,
    cartPriceState,
    openCart,
    closeCart,
    addCartItem,
    addCartMultiItem,
    removeCartItem,
    calcCart,
    cartLine,
    calculateLoaded,
    getCartCalcByPaymentType,
    checkAndUseManualCoupon,
    resetManualCoupon,
    resetCart,
    resetPaymentTypeAndCoupon,
    // amountTotal,
    // amountTotalExcludeTax,
    // amountTax,
    // cartLine,
  }
}

export type UseCartStore = ReturnType<typeof provideUseCart>

const UseCartKey: InjectionKey<UseCartStore> = Symbol("UseCartStore")

export default UseCartKey

export const defineCart = (): void => {
  return provide(UseCartKey, provideUseCart())
}

export const useCart = () => {
  const state = inject(UseCartKey)
  if (!state) {
    throw new Error("NO Global Key")
  }

  return state
}
